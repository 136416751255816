<template>
  <div class="dashboard-table-card-container">
    <le-card width="100%" style="padding-bottom: 20px">
      <template>
        <div class="container">
            <div class="card-title" style="display: flex; ">
              虚拟素人主播
              <div class="num-box">{{ pageOptions.total }}人</div>
            </div>
          <div v-if="!isMobile">
            <a-row class="select-where">
              <a-col :span="6" style="display: flex; align-items: center">
                <div style="width: 70px">主播类型：</div>
                <a-select
                        default-value="0"
                        v-model="signing_status"
                        style="width: 200px"
                >
                  <a-select-option :value="0"> 全部</a-select-option>
                  <a-select-option :value="1"> 有公会</a-select-option>
                  <a-select-option :value="2"> 无公会</a-select-option>
                  <a-select-option :value="3"> 未知</a-select-option>
                </a-select>
              </a-col>

              <a-col :span="6" style="display: flex; align-items: center">
                <div style="width: 70px">是否繁星：</div>
                <a-select
                        default-value="0"
                        v-model="is_stars"
                        style="width: 200px"
                >
                  <a-select-option :value="0"> 全部</a-select-option>
                  <a-select-option :value="1"> 是</a-select-option>
                  <a-select-option :value="2"> 否</a-select-option>
                </a-select>
              </a-col>

              <a-col :span="6" style="display: flex; align-items: center">
                <div style="width: 70px">跟进：</div>
                <a-select
                        default-value="0"
                        v-model="follow_up"
                        style="width: 200px"
                >
                  <a-select-option :value="0"> 全部</a-select-option>
                  <a-select-option :value="-1"> 无跟进</a-select-option>
                  <a-select-option :value="1"> 我跟进的</a-select-option>
                </a-select>
              </a-col>

              <a-col :span="6" style="display: flex; align-items: center">
                <div style="width: 70px">舰长数：</div>
                <a-select v-model="guard_type" style="width: 200px">
                  <a-select-option :value="0"> 全部</a-select-option>
                  <a-select-option :value="1"> 0~3 舰长</a-select-option>
                  <a-select-option :value="2"> 4~10 舰长</a-select-option>
                  <a-select-option :value="3"> 11~30 舰长</a-select-option>
                  <a-select-option :value="4"> 30 舰长以上</a-select-option>
                </a-select>
              </a-col>
            </a-row>

            <a-row style="margin: 0 30px">
              <a-col :span="6" style="display: flex; align-items: center">
                <div style="width: 70px">新人：</div>
                <a-select
                        default-value="0"
                        v-model="is_new_anchor"
                        style="width: 200px"
                >
                  <a-select-option :value="0"> 全部</a-select-option>
                  <a-select-option :value="1"> 是</a-select-option>
                  <a-select-option :value="2"> 否</a-select-option>
                </a-select>
              </a-col>
              <a-col
                      :span="6"
                      style="display: flex; align-items: center; height: 32px"
              >
                <le-button
                        txt="查询"
                        width="40px"
                        height="25px"
                        fontSize="14px"
                        style="margin-right: 10px"
                        @btnClick="searchData()"
                />
                <le-button
                        txt="重置"
                        width="40px"
                        height="25px"
                        fontSize="14px"
                        background="linear-gradient(-10deg, #FD8A8F, #FA6A9D)"
                        @btnClick="resetGetData()"
                />
                <le-button
                        :loading="isLoad"
                        v-if="btn.isExportAll"
                        txt="导出当前查询记录"
                        width="120px"
                        height="25px"
                        fontSize="14px"
                        style="margin-left: 10px"
                        @btnClick="
                  modalText = '最多导出前10000条数据';
                  exprotNum = 10000;
                  exShowModal();
                "
                />
              </a-col>

              <a-col
                      :span="6"
                      style="display: flex; align-items: center; height: 32px"
                      v-if="btn.isExport"
              >
                导出前
                <a-input-number
                        v-model="value"
                        :min="1"
                        :max="10000"
                        size="small"
                />
                条
                <le-button
                        txt="导出"
                        width="40px"
                        height="25px"
                        fontSize="14px"
                        style="margin-left: 10px"
                        @btnClick="
                  exportVisible = true;
                  modalText = `导出前${value}条数据`;
                  exprotNum = value;
                "
                />
              </a-col>
            </a-row>
          </div>

          <!--手机查询框-->
          <div v-if="isMobile">
            <a-row class="select-where">
              <a-col :span="12" class="select-col">
                <div class="select-tit">主播类型：</div>
                <a-select
                        default-value="0"
                        v-model="signing_status"
                        style="width: 200px"
                >
                  <a-select-option :value="0"> 全部</a-select-option>
                  <a-select-option :value="1"> 有公会</a-select-option>
                  <a-select-option :value="2"> 无公会</a-select-option>
                  <a-select-option :value="3"> 未知</a-select-option>
                </a-select>
              </a-col>

              <a-col :span="12" class="select-col">
                <div class="select-tit">是否繁星：</div>
                <a-select
                        default-value="0"
                        v-model="is_stars"
                        style="width: 200px"
                >
                  <a-select-option :value="0"> 全部</a-select-option>
                  <a-select-option :value="1"> 是</a-select-option>
                  <a-select-option :value="2"> 否</a-select-option>
                </a-select>
              </a-col>
            </a-row>
            <a-row class="select-where">
              <!--              <a-col :span="12" class="select-col">-->
              <!--                <div class="select-tit">跟进：</div>-->
              <!--                <a-select-->
              <!--                        default-value="0"-->
              <!--                        v-model="follow_up"-->
              <!--                        style="width: 200px"-->
              <!--                >-->
              <!--                  <a-select-option :value="0"> 全部</a-select-option>-->
              <!--                  <a-select-option :value="-1"> 无跟进</a-select-option>-->
              <!--                  <a-select-option :value="1"> 我跟进的</a-select-option>-->
              <!--                </a-select>-->
              <!--              </a-col>-->
              <a-col :span="12" class="select-col">
                <div class="select-tit">新人：</div>
                <a-select
                        default-value="0"
                        v-model="is_new_anchor"
                        style="width: 200px"
                >
                  <a-select-option :value="0"> 全部</a-select-option>
                  <a-select-option :value="1"> 是</a-select-option>
                  <a-select-option :value="2"> 否</a-select-option>
                </a-select>
              </a-col>
              <a-col :span="12" class="select-col">
                <div class="select-tit">舰长数：</div>
                <a-select v-model="guard_type" style="width: 200px">
                  <a-select-option :value="0"> 全部</a-select-option>
                  <a-select-option :value="1"> 0~3 舰长</a-select-option>
                  <a-select-option :value="2"> 4~10 舰长</a-select-option>
                  <a-select-option :value="3"> 11~30 舰长</a-select-option>
                  <a-select-option :value="4"> 30 舰长以上</a-select-option>
                </a-select>
              </a-col>
            </a-row>
            <a-row class="select-where">
              <a-col :span="12" class="select-col">
                <div style="width: 68px">UID：</div>
                <a-input
                        :min="1"
                        v-model="uid"
                        placeholder="输入UID"
                ></a-input>
              </a-col>
              <a-col :span="12" class="select-col">
                <div style="width: 86px">房间号：</div>
                <a-input
                        :min="1"
                        v-model="room_id"
                        placeholder="输入房间号"
                ></a-input>
              </a-col>
            </a-row>
            <a-row>
              <a-col
                      :span="24"
                      style="display: flex; align-items: center; height: 32px;justify-content: center;"
              >
                <le-button
                        txt="查询"
                        width="40px"
                        height="25px"
                        fontSize="14px"
                        style="margin-right: 10px"
                        @btnClick="searchData()"
                />
                <le-button
                        txt="重置"
                        width="40px"
                        height="25px"
                        fontSize="14px"
                        background="linear-gradient(-10deg, #FD8A8F, #FA6A9D)"
                        @btnClick="resetGetData()"
                />
              </a-col>
            </a-row>
          </div>
<!--            <a-row style="margin: 30px;">-->

<!--<a-col :span="6" style="display: flex;align-items: center;">-->
<!--<div style="width: 70px;">主播类型：</div>-->
<!--  <a-select default-value="0" v-model="signing_status" style="width: 200px" >-->
<!--    <a-select-option :value="0">-->
<!--      全部-->
<!--    </a-select-option>-->
<!--    <a-select-option :value="1">-->
<!--      有公会-->
<!--    </a-select-option>-->
<!--    <a-select-option :value="2">-->
<!--      无公会-->
<!--    </a-select-option>-->
<!--    <a-select-option :value="3">-->
<!--      未知-->
<!--    </a-select-option>-->
<!--  </a-select>-->
<!--</a-col>-->

<!--<a-col :span="6" style="display: flex;align-items: center;">-->
<!--<div style="width: 70px;">是否繁星：</div>-->
<!--  <a-select default-value="0" v-model="is_stars" style="width: 200px" >-->
<!--    <a-select-option :value="0">-->
<!--      全部-->
<!--    </a-select-option>-->
<!--    <a-select-option :value="1">-->
<!--      是-->
<!--    </a-select-option>-->
<!--    <a-select-option :value="2">-->
<!--      否-->
<!--    </a-select-option>-->
<!--  </a-select>-->
<!--</a-col>-->

<!--<a-col :span="6" style="display: flex;align-items: center;">-->
<!--<div style="width: 70px;">跟进：</div>-->
<!--  <a-select default-value="0" v-model="follow_up" style="width: 200px" >-->
<!--    <a-select-option :value="0">-->
<!--      全部-->
<!--    </a-select-option>-->
<!--    <a-select-option :value="-1">-->
<!--      无跟进-->
<!--    </a-select-option>-->
<!--    <a-select-option :value="1">-->
<!--      我跟进的-->
<!--    </a-select-option>-->
<!--  </a-select>-->
<!--</a-col>-->

<!--<a-col :span="6" style="display: flex;align-items: center;">-->
<!--<div style="width: 70px;">舰长数：</div>-->
<!--  <a-select v-model="guard_type" style="width: 200px" >-->
<!--    <a-select-option :value="0">-->
<!--      全部-->
<!--    </a-select-option>-->
<!--    <a-select-option :value="1">-->
<!--      0~3 舰长-->
<!--    </a-select-option>-->
<!--    <a-select-option :value="2">-->
<!--      4~10 舰长-->
<!--    </a-select-option>-->
<!--    <a-select-option :value="3">-->
<!--      11~30 舰长-->
<!--    </a-select-option>-->
<!--    <a-select-option :value="4">-->
<!--      30 舰长以上-->
<!--    </a-select-option>-->
<!--  </a-select>-->
<!--</a-col>-->
<!--</a-row>-->

<!--<a-row style="margin:0 30px;">-->
<!--<a-col :span="6" style="display: flex;align-items: center;">-->
<!--<div style="width: 70px;">新人：</div>-->
<!--  <a-select default-value="0" v-model="is_new_anchor" style="width: 200px" >-->
<!--    <a-select-option :value="0">-->
<!--      全部-->
<!--    </a-select-option>-->
<!--    <a-select-option :value="1">-->
<!--      是-->
<!--    </a-select-option>-->
<!--    <a-select-option :value="2">-->
<!--      否-->
<!--    </a-select-option>-->
<!--  </a-select>-->
<!--</a-col>-->
<!--<a-col :span="6" style="display: flex;align-items: center;height: 35px;">-->
<!--  <le-button txt="查询" width="40px" height="25px" fontSize="14px" style="margin-right: 10px;" @btnClick="searchData()" />-->
<!--  <le-button txt="重置" width="40px" height="25px" fontSize="14px" background="linear-gradient(-10deg, #FD8A8F, #FA6A9D)"  @btnClick="resetGetData()" />-->
<!--  <le-button :loading="isLoad" v-if="btn.isExportAll" txt="导出当前查询记录" width="120px" height="25px" fontSize="14px" style="margin-left: 10px;" @btnClick="modalText='最多导出前10000条数据';exprotNum=10000;exShowModal();" />-->
<!--</a-col>-->

<!--<a-col :span="6" style="display: flex;align-items: center;height: 35px;" v-if="btn.isExport"   >-->
<!--  导出前-->
<!--  <a-input-number v-model="value" :min="1" :max="10000" size="small" />-->
<!--  条-->
<!--  <le-button txt="导出" width="40px" height="25px" fontSize="14px" style="margin-left: 10px;" @btnClick="exportVisible=true;modalText=`导出前${value}条数据`;exprotNum=value" />-->
<!--</a-col>-->
<!--</a-row>-->

          <div class="table-box">
            <!--pc-->
            <a-table
              :columns="columns"
              :data-source="dataSource"
              :loading="loading"
              :pagination="pageOptions"
              @change="handleTableChange"
              size="middle"
              v-if="!isMobile"
            >
              <div slot="uname" slot-scope="row">
                <a
                  class="name-box"
                  :href="'https://space.bilibili.com/' + row.bili_id"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div class="avatar">
                    <img :src="row.face" alt="" srcset="" />
                  </div>
                  <div class="name-uid">
                    <div >{{ row.uname }}</div>
                    <div>uid:{{ row.bili_id }}</div>
                  </div>
                </a>
              </div>

              <div slot="is_sign" slot-scope="row">
                <div style="color: #ff6363" v-if="row.signing_status == 1">已签约</div>
                <div style="color: #4acc7f" v-else-if="row.signing_status == 2">未签约</div>
                <div style="color: #ff6363" v-else-if="row.signing_status == 3">鹿角科技</div>
                <div style="color: #333" v-else>--</div>
              </div>
              <div slot="is_new_anchor" slot-scope="row">
                <div >{{row.is_new_anchor == 1?'是':'否' }}</div>
              </div>
              <div slot="is_stars" slot-scope="row">
                <div >{{row.is_stars == 1?'是':'否' }}</div>
              </div>

              <div slot="remarks" slot-scope="row">
                <span style="color:#ddd" v-if="row.root_into_admin_id&&info.uid!=row.root_into_admin_id" >
                  <div v-if="row.remarks.length==0" >无</div>
                  <div v-else  > {{row.remarks[0].remarks}}</div>
                </span>
                <div v-else>
                  <a v-if="row.remarks.length==0"   @click="showRemark(row)"  >无</a>
                  <a v-else  @click="showRemark(row)"  > {{row.remarks[0].remarks}}</a>
                </div>
              </div>

              <div slot="contact_way" slot-scope="row">
                <span style="color:#ddd" v-if="row.root_into_admin_id&&info.uid!=row.root_into_admin_id" >{{row.contact_way?row.contact_way:'--'}}</span>
                <a  @click="cvis=true;rows=row" v-else > {{row.contact_way?row.contact_way:'---'}}</a>
              </div>

              <span slot="customTitle"
                >开播率
                <a-tooltip>
                  <template slot="title"> 近3天直播过 记为有效 </template>
                  <a-icon type="question-circle" />
                </a-tooltip>
              </span>

              <span slot="action" slot-scope="row">
              <a @click="visible=true;rows=row" >详情</a>
              <a-divider type="vertical" />
              <a   target="_blank"  :href="'https://live.bilibili.com/'+ row.roomid"   >直播间</a>
              <a-divider type="vertical" />
              <span style="color:#ddd"  v-if="row.root_into_admin_id&&info.uid!=row.root_into_admin_id" >已跟进</span>
              <a @click="rootInto(row.id)"  v-else-if="row.root_into_admin_id" >取消跟进</a>
              <a @click="rootInto(row.id)"  v-else >跟进</a>
              <a-divider type="vertical"/>
              <a @click="renovate(row.id)" >更新</a>
              <a-divider type="vertical"/>

              <a-popconfirm title="确定入会吗？" ok-text="确定" cancel-text="取消"   @confirm="upd(row.bili_id,1)" v-if="row.initiate_status==2" >
                <a  >入会 </a>
              </a-popconfirm>
              
              <a-popconfirm title="确定退会吗？" ok-text="确定" cancel-text="取消"   @confirm="upd(row.bili_id,2)" v-else >
                <a >退会  </a>
              </a-popconfirm>

              </span>
            </a-table>
            <!--手机-->
            <a-table
                    :columns="columnsM"
                    :data-source="dataSource"
                    :loading="loading"
                    :pagination="pageOptions"
                    @change="handleTableChange"
                    size="middle"
                    v-if="isMobile"
            >
              <div slot="uname" slot-scope="row">
                <a
                        class="name-box"
                        :href="'https://space.bilibili.com/' + row.bili_id"
                        target="_blank"
                        rel="noopener noreferrer"
                >
                  <div class="avatar">
                    <img :src="row.face" alt="" srcset=""/>
                    <div v-if="row.signing_status === 1" style="color: red">已签</div>
                    <div v-if="row.signing_status === 2" style="color: green">未签</div>
                    <div v-if="row.signing_status === 3" style="color: yellow">鹿角</div>
                  </div>
                  <div class="name-uid">
                    <div>{{ row.uname }}</div>
                    <div>uid:{{ row.bili_id }}</div>
                    <div>舰长数:{{ row.guard_num }}</div>
                    <div v-if="row.star_level_name != false">繁星:{{ row.star_level_name }}</div>

                  </div>
                </a>
              </div>

              <span slot="action" slot-scope="row">
                <a
                        @click="
                    visible = true;
                    rows = row;
                  "
                >详情</a
                >
                <a-divider type="vertical"/>
                <a
                        target="_blank"
                        :href="'https://live.bilibili.com/' + row.roomid"
                >直播间</a
                >
                <a-divider type="vertical"/>
                <a @click="renovate(row.id)">更新</a>
              </span>
            </a-table>
          </div>
        </div>
      </template>
    </le-card>
    <popEdit
      v-if="visible"
      :visible="visible"
      :item="rows"
      @handleOk="visible=false"
      @handleCancel="visible=false"
    />
    <popContact
      v-if="cvis"
      :visible="cvis"
      :row="rows"
      @handleOk="cvis=false;getData()"
      @handleCancel="cvis=false"
    />
    <popRemark
      v-if="rvis"
      :visible="rvis"
      :row="rows"
      :dataSource="rows.remarks"
      @handleOk="rvis=false;getData()"
      @handleCancel="rvis=false"
    />

    <a-modal
      title="导出确认"
      :visible="exportVisible"
      :confirm-loading="confirmLoading"
      okText="确认导出"
      @ok="exListExcel()"
      @cancel="exportVisible=false"
      :maskClosable="false"
      :closable="false"
    >
      <p>{{ modalText }}</p>
      <p>导出数据需要一点时间，请耐心等待</p>
    </a-modal>



  </div>
</template>

<script>

import popEdit from "./popEdit";
import popContact from "./popContact";
import popRemark from "./popRemark";
import { MixinList } from "@/common/mixin";
import { Mx } from "./mixin";

export default {
  components: {
    popEdit,
    popContact,
    popRemark
  },
  data() {
    return {
      dataUrl: "v2/anchorDataInvented/sel",
      delUrl: "admin/layout/del",
      isMobile: false,
    };
  },
  created(){
    this.queryParam.signing_status=2
  },
  computed: {},
  mounted() {
    if (
            navigator.userAgent.match(
                    /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
            )
    )
      this.isMobile = true;
  },
  mixins: [MixinList,Mx],
  methods: {
    // 退会入会操作
    upd(bili_id){
        this.$api
        .post(`v2/anchor/enterGuild`, { bili_id ,type:2})
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("更新成功");
            this.getData();
          } else {
            this.$message.error(res.message);
          }
        });
    },

    // 刷新主播信息
    renovate(id){
        this.$api
        .post(`v2/anchorDataInvented/renovate`, { id })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("更新成功");
            // this.getData();
          } else {
            this.$message.error(res.message);
          }
        });
    },

    // 游戏主播跟进退跟
    rootInto(id){
        this.$api
        .post(`v2/anchorDataInvented/rootInto`, { id })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("操作成功");
            this.getData();
          } else {
            this.$message.error(res.message);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
  .dashboard-table-card-container {
    .container {
      position: relative;

      .num-box {
        font-size: 14px;
        margin-left: 11px;
        line-height: 32px;
      }

      .table-box {
        margin: 40px 30px;
      }

      .card-title {
        display: flex
      }

      .select-where {
        margin: 30px;

        .select-col {
          display: flex;
          align-items: center;
          .select-tit{
            width: 70px
          }
        }


      }
    }
  }

  .btn-canle {
    width: 70px;
    height: 30px;
    background: linear-gradient(-10deg, #fd8a8f, #fa6a9d);
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    line-height: 30px;
    color: #fff;
    margin-left: 10px;
  }

  .login {
    cursor: pointer;

    &:hover {
      color: #008df0;
    }
  }

  .name-box {
    display: flex;

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 10px;
      background-color: #333333;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
  }

  @media screen and(max-width: 750px) {
    .dashboard-table-card-container {
      .container {
        .num-box {
          font-size: 12px;
          margin-left: 5px;
          line-height: 22px;
        }

        .table-box {
          margin: 20px;
          overflow-x: scroll;
        }

        .select-where {
          margin: 10px;

          .select-col {
            display: flex;
            align-items: center;
            .select-tit{
              width: 150px
            }
          }


        }
      }
    }
  }
</style>
