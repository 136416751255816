const columns = [
  {
    title: "昵称/UID",
    scopedSlots: {customRender: "uname"},
    width: (navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i)) ? 300 : 200,
  },
  {
    title: "签约状态",
    width: 100,
    scopedSlots: {customRender: "is_sign"},
  },

  {
    title: "是否是新人",
    width: 100,
    scopedSlots: {customRender: "is_new_anchor"}
  },
  {
    title: "是否是繁星",
    width: 100,
    scopedSlots: {customRender: "is_stars"}
  },
  {
    title: "跟进人",
    width: 100,
    dataIndex: 'root_into_uname'
  },
  {
    title: "大航海",
    width: 100,
    dataIndex: 'guard_num'
  },
  // {
  //     title: "备注",
  //     width: 100,
  //     scopedSlots: {customRender: "remarks"}
  // },
  // {
  //     title: "联系方式",
  //     width: 100,
  //     scopedSlots: {customRender: "contact_way"}
  // },
  {
    title: "操作",
    width: (navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i)) ? 150 : 280,
    key: "action",
    scopedSlots: {customRender: "action"},
    fixed: "right",
  },
];

const columnsM = [
  {
    title: "昵称/UID",
    scopedSlots: {customRender: "uname"},
    width: (navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i)) ? 300 : 200,
  },
  {
    title: "操作",
    width: (navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i)) ? 150 : 280,
    key: "action",
    scopedSlots: {customRender: "action"},
    fixed: "right",
  },
];

import ExportJsonExcel from 'js-export-excel';

const Mx={
    data(){
      return {
        columns,
        columnsM,
        isLoad: false,
        visible: false,
        cvis: false,
        rvis: false,
        exportVisible: false,
        confirmLoading: false,
        modalText: '最多导出前10000条数据',
        exprotNum:1,
        type: 'uname',
        rows: {},
        fleet:0,
        value:1,
        signing_status: 2,
        is_stars: 0,
        follow_up: 0,
        is_new_anchor: 0,
        guard_type:0,
        keyword: '',
        uid: '',
        room_id: '',
        info:{},
        btn:{
          isExport: false,
          isExportAll: false
        }

      }
    },
    components: {

    },
    beforeMount() {
      this.queryParam.is_sign = 0;
    },
    mounted() {
      this.info=JSON.parse(window.localStorage.getItem("info"))
      let btn=window.localStorage.getItem('whiteBtns')
      if(btn){
        let btns=btn.split(',');
        if(btns.includes('anchor-manager-invent-export')){
          this.btn.isExport = true;
        }
        if(btns.includes('anchor-manager-invent-exporta')){
          this.btn.isExportAll = true;
        }
      }
    },
    methods:{
      showRemark(row){
        this.rvis=true;
        this.rows=row
        this.rows.type=2
        if(!row.root_into_admin_id){
          this.rootInto(row.id)
        }
        if(this.rows.remarks.length > 0){
          this.rows.value=this.rows.remarks[0].remarks
        }else{
          this.rows.value='无'
        }
      },
      exShowModal(){
        if(this.pageOptions.total>10000){
          this.exportVisible=true;
        }else{
          this.isLoad=true
          this.exListExcel()
        }
      },
  
      //导出前几条数据
      exportExcel(){
        let arr1 = JSON.parse(JSON.stringify(this.dataSource))
        let arr2 = arr1.splice(0,this.value)
        this.downloadExcel(arr2)
      },
  
      // 导出excle
      exListExcel(){
        this.confirmLoading = true
        let params=  JSON.parse(JSON.stringify(this.queryParam));
        params.page=1

        params.perPage=this.exprotNum
        this.$api.get(this.dataUrl,{params}).then((res) => {
          this.isLoad=false
          if(res.code==200) {
            this.downloadExcel(res.data.list)
          }else{
            this.$message.error(res.message)
            this.confirmLoading = false
          }
        })
      },
  
      // 导出Excel 
      downloadExcel(datas){
          var option = {};
          let dataTable = [];
          if (datas) {
            for (let i in datas) {
              let obj = {
                'ID': datas[i].id,
                '昵称': datas[i].uname,
                'uid': datas[i].bili_id,
                '房间号': datas[i].roomid,
                '签约状态': datas[i].signing_status==2?'未签约':'已签约',
                '是否是新人': datas[i].is_new_anchor==2?'否':'是',
                '是否是繁星': datas[i].is_stars==2?'否':'是',
                '跟进人': datas[i].root_into_uname,
                '联系方式': datas[i].contact_way,
                '最后开播时间': datas[i].last_broadcast_time,
                '有效天': datas[i].effective_day
                }
              dataTable.push(obj);
            }
          }
          option.fileName = '虚拟素人主播列表'
          option.datas = [
            {
              sheetData: dataTable,
              sheetName: 'sheet',
              sheetFilter: ['ID', '昵称', 'uid', '签约状态','房间号','是否是新人','是否是繁星','跟进人','联系方式','最后开播时间','有效天'],
              sheetHeader: ['ID', '昵称', 'uid', '签约状态','房间号','是否是新人','是否是繁星','跟进人','联系方式','最后开播时间','有效天'],
            }
          ];
          var toExcel = new ExportJsonExcel(option);
          toExcel.saveExcel();
          this.confirmLoading = false
          this.exportVisible=false
  
      },

    // 搜索数据
    searchData(){
      this.queryParam.page = 1;
      console.log('signing_status', this.signing_status);
      if(this.signing_status==0){
        delete this.queryParam.signing_status
      }else{
        this.queryParam.signing_status = this.signing_status;
      }

      if(this.is_stars==0){
        delete this.queryParam.is_stars
      }else{
        this.queryParam.is_stars = this.is_stars
      }

      if(this.follow_up==0){
        delete this.queryParam.follow_up
      }else{
        this.queryParam.follow_up = this.follow_up
      }

      if(this.is_new_anchor==0){
        delete this.queryParam.is_new_anchor
      }else{
        this.queryParam.is_new_anchor = this.is_new_anchor
      }

      if(this.guard_type==0){
        delete this.queryParam.guard_type
      }else{
        this.queryParam.guard_type = this.guard_type
      }
      if(this.keyword===''){
        delete this.queryParam.keyword
      }else{
        this.queryParam.keyword = this.keyword
      }
      if (this.uid === '') {
        delete this.queryParam.uid
      } else {
        this.queryParam.uid = this.uid
      }
      if (this.room_id === '') {
        delete this.queryParam.room_id
      } else {
        this.queryParam.room_id = this.room_id
      }

      console.log(this.queryParam)
      this.getData();
    },


    // 重设数据
    resetGetData(){
      let perPage=this.queryParam.perPage
      this.queryParam={}
      this.queryParam.page = 1;
      this.queryParam.perPage = perPage;

      this.fleet=0
      this.value=0
      this.signing_status=0
      this.is_stars=0
      this.follow_up=0
      this.is_new_anchor=0
      this.guard_type=0
      this.getData();
    },

    //搜索
    onSearch(value) {
      if(!value){
        delete this.queryParam.keyword
        delete this.queryParam.search_by
      }else{
      this.queryParam.keyword =value
      this.queryParam.search_by = this.type
      }
      this.queryParam.page = 1;
      console.log(this.queryParam);
      this.getData();
    },

    changeTab(index) {
      this.queryParam.is_sign = index;
      this.queryParam.page = 1;
      this.getData();
    },
    handleOk() {
      this.visible = false;
      this.getData();
    },
    handleCancel() {
      this.visible = false;
      this.getData();
    }
    }
}


export { Mx}
